{
   "neutron.limblecmms.com": {
      "logoURL": "assets/logos/neutron-light-background.png",
      "logoURLDark": "assets/logos/neutron-dark-background.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [1183],
      "pageTitle": "Neutron",
      "favicon16": "assets/favicons/neutron16.png",
      "favicon32": "assets/favicons/neutron32.png",
      "favicon96": "assets/favicons/neutron96.png"
   },
   "jbmpackaging.limblecmms.com": {
      "logoURL": "assets/logos/jbm_horz_full_color.svg",
      "logoURLDark": "assets/logos/jbm_horz_full_color.svg",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [13936],
      "pageTitle": "JBM Packaging",
      "favicon16": "assets/favicons/jbm16x16.png",
      "favicon32": "assets/favicons/jbm32x32.png",
      "favicon96": "assets/favicons/jbm32x32.png"
   },
   "dhl.limblecmms.com": {
      "logoURL": "assets/logos/DHL.png",
      "logoURLDark": "assets/logos/DHL.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px",
         "margin-left": "25px"
      },
      "poweredBy": false,
      "showFeatureRequestBoard": true,
      "validCustomers": [695],
      "pageTitle": "DHL",
      "favicon16": "assets/favicons/dhl16.png",
      "favicon32": "assets/favicons/dhl32.png",
      "favicon96": "assets/favicons/dhl96.png"
   },
   "enco.limblecmms.com": {
      "logoURL": "assets/logos/enco.png",
      "logoURLDark": "assets/logos/enco-dark-background.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": false,
      "showFeatureRequestBoard": true,
      "validCustomers": [774],
      "pageTitle": "Enco",
      "favicon16": "assets/favicons/enco16.png",
      "favicon32": "assets/favicons/enco32.png",
      "favicon96": "assets/favicons/enco96.png"
   },
   "deliver-ez.limblecmms.com": {
      "logoURL": "assets/logos/Deliverez_Logo_New_NoBG.png",
      "logoURLDark": "assets/logos/Deliverez_Logo_New_NoBG.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": false,
      "showFeatureRequestBoard": true,
      "validCustomers": [807],
      "pageTitle": "Deliver-EZ",
      "favicon16": "assets/favicons/deliverEZ16.png",
      "favicon32": "assets/favicons/deliverEZ32.png",
      "favicon96": "assets/favicons/deliverEZ96.png"
   },
   "epic.limblecmms.com": {
      "logoURL": "assets/logos/epicGames.png",
      "logoURLDark": "assets/logos/epicGames.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [1181],
      "pageTitle": "Epic Games",
      "favicon16": "assets/favicons/epicGames16.png",
      "favicon32": "assets/favicons/epicGames32.png",
      "favicon96": "assets/favicons/epicGames96.png"
   },
   "invata.limblecmms.com": {
      "logoURL": "assets/logos/invata-light-background.png",
      "logoURLDark": "assets/logos/invata-dark-background.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": false,
      "showFeatureRequestBoard": true,
      "validCustomers": [1835],
      "pageTitle": "Invata",
      "favicon16": "assets/favicons/invata16.png",
      "favicon32": "assets/favicons/invata32.png",
      "favicon96": "assets/favicons/invata96.png"
   },
   "iotplus.limblecmms.com": {
      "logoURL": "assets/logos/iotplus-light-background.png",
      "logoURLDark": "assets/logos/iotplus-light-background.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": false,
      "showFeatureRequestBoard": true,
      "validCustomers": [1920],
      "pageTitle": "IOT Plus",
      "favicon16": "assets/favicons/iotPlus16.png",
      "favicon32": "assets/favicons/iotPlus32.png",
      "favicon96": "assets/favicons/iotPlus96.png"
   },
   "smartwalk.limblecmms.com": {
      "logoURL": "assets/logos/pmcgroup-light-background.png",
      "logoURLDark": "assets/logos/pmcgroup-dark-background.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": false,
      "showFeatureRequestBoard": false,
      "validCustomers": [1712, 1971, 2071, 2106],
      "pageTitle": "PMC Group",
      "favicon16": "assets/favicons/pmc16.png",
      "favicon32": "assets/favicons/pmc32.png",
      "favicon96": "assets/favicons/pmc96.png"
   },
   "pmcgroup.limblecmms.com": {
      "logoURL": "assets/logos/pmcgroup-light-background.png",
      "logoURLDark": "assets/logos/pmcgroup-dark-background.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": false,
      "showFeatureRequestBoard": false,
      "validCustomers": [1712, 1971, 2071, 2106],
      "pageTitle": "PMC Group",
      "favicon16": "assets/favicons/pmc16.png",
      "favicon32": "assets/favicons/pmc32.png",
      "favicon96": "assets/favicons/pmc96.png"
   },
   "demirer.limblecmms.com": {
      "logoURL": "assets/logos/demirer-hodling.png",
      "logoURLDark": "assets/logos/demirer-hodling.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [36, 347],
      "pageTitle": "Demirer",
      "favicon16": "assets/favicons/demirer16.png",
      "favicon32": "assets/favicons/demirer32.png",
      "favicon96": "assets/favicons/demirer96.png"
   },
   "scandinaviandrone.limblecmms.com": {
      "logoURL": "assets/logos/ScandinavianDroneLogoSvart.png",
      "logoURLDark": "assets/logos/ScandinavianDroneLogoHvit.png",
      "logoURLDarkCSS": {
         "height": "30px",
         "margin-top": "5px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": false,
      "validCustomers": [36, 2116],
      "pageTitle": "Scandinavian Drone",
      "favicon16": "assets/favicons/scandinavianDrone16.png",
      "favicon32": "assets/favicons/scandinavianDrone32.png",
      "favicon96": "assets/favicons/scandinavianDrone96.png"
   },
   "comstockfs.limblecmms.com": {
      "logoURL": "assets/logos/Comstock-logo-transparent.png",
      "logoURLDark": "assets/logos/Comstock-logo-transparent.png",
      "logoURLDarkCSS": {
         "height": "40px",
         "margin-top": "0px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": false,
      "validCustomers": [2709],
      "pageTitle": "Comstock",
      "favicon16": "assets/favicons/comstock16.png",
      "favicon32": "assets/favicons/comstock32.png",
      "favicon96": "assets/favicons/comstock96.png"
   },
   "harrigan.limblecmms.com": {
      "logoURL": "assets/logos/HarriganLogo.png",
      "logoURLDark": "assets/logos/HarriganLogo.png",
      "logoURLDarkCSS": {
         "height": "40px",
         "margin-top": "0px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [182],
      "pageTitle": "Harrigan",
      "favicon16": "assets/favicons/harrigan16.png",
      "favicon32": "assets/favicons/harrigan32.png",
      "favicon96": "assets/favicons/harrigan96.png"
   },
   "sentegrity.limblecmms.com": {
      "logoURL": "assets/logos/sentegrity.png",
      "logoURLDark": "assets/logos/sentegrity.png",
      "logoURLDarkCSS": {
         "height": "40px",
         "margin-top": "0px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [3364],
      "pageTitle": "Sentegrity",
      "favicon16": "assets/favicons/sentegrity16.png",
      "favicon32": "assets/favicons/sentegrity32.png",
      "favicon96": "assets/favicons/sentegrity96.png"
   },
   "pbe.limblecmms.com": {
      "logoURL": "assets/logos/PBE_Light.svg",
      "logoURLDark": "assets/logos/PBE_Dark.svg",
      "logoURLDarkCSS": {
         "height": "40px",
         "margin-top": "0px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [5714],
      "pageTitle": "PBE",
      "favicon16": "assets/favicons/pbeFavicon.png",
      "favicon32": "assets/favicons/pbeFavicon.png",
      "favicon96": "assets/favicons/pbeFavicon.png"
   },
   "redaptive.limblecmms.com": {
      "logoURL": "assets/logos/redaptive_logo_dark.svg",
      "logoURLDark": "assets/logos/redaptive_logo_light.svg",
      "logoURLDarkCSS": {
         "height": "16px",
         "margin-top": "13px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [6135],
      "pageTitle": "Redaptive",
      "favicon16": "assets/favicons/redaptive_favicon-16x16.png",
      "favicon32": "assets/favicons/redaptive_favicon-32x32.png",
      "favicon96": "assets/favicons/redaptive_favicon-32x32.png"
   },
   "johndeere.limblecmms.com": {
      "logoURL": "assets/logos/johndeere_logo.svg",
      "logoURLDark": "assets/logos/johndeere_logo.svg",
      "logoURLDarkCSS": {
         "height": "16px",
         "margin-top": "13px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [9266],
      "pageTitle": "John Deere - Australia",
      "favicon16": "assets/favicons/johndeere16.png",
      "favicon32": "assets/favicons/johndeere32.png",
      "favicon96": "assets/favicons/johndeere96.png"
   },
   "kettering.limblecmms.com": {
      "logoURL": "assets/logos/KU.svg",
      "logoURLDark": "assets/logos/KU.svg",
      "logoURLDarkCSS": {
         "height": "16px",
         "margin-top": "13px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [9629],
      "pageTitle": "Kettering University",
      "favicon16": "assets/favicons/KU-16x16.png",
      "favicon32": "assets/favicons/KU-16x16.png",
      "favicon96": "assets/favicons/KU-16x16.png"
   },
   "homefix.limblecmms.com": {
      "logoURL": "assets/logos/homeFixLogo.svg",
      "logoURLDark": "assets/logos/homeFixLogo.svg",
      "logoURLDarkCSS": {
         "height": "16px",
         "margin-top": "13px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [11978],
      "pageTitle": "Home Fix LLC",
      "favicon16": "assets/favicons/homeFix16x16.png",
      "favicon32": "assets/favicons/homeFix16x16.png",
      "favicon96": "assets/favicons/homeFix16x16.png"
   },
   "intelsat.limblecmms.com": {
      "logoURL": "assets/logos/intelsat.svg",
      "logoURLDark": "assets/logos/intelsat.svg",
      "logoURLDarkCSS": {
         "height": "16px",
         "margin-top": "13px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [11213],
      "pageTitle": "Intelsat Facility Maintenance (IFM)",
      "favicon16": "assets/favicons/intelsat16x16.png",
      "favicon32": "assets/favicons/intelsat16x16.png",
      "favicon96": "assets/favicons/intelsat16x16.png"
   },
   "access.limblecmms.com": {
      "logoURL": "assets/logos/access.svg",
      "logoURLDark": "assets/logos/access.svg",
      "logoURLDarkCSS": {
         "height": "16px",
         "margin-top": "13px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [18238],
      "pageTitle": "Access Community",
      "favicon16": "assets/favicons/access16.png",
      "favicon32": "assets/favicons/access32.png",
      "favicon96": "assets/favicons/access96.png"
   },
   "canyonridgeservices.limblecmms.com": {
      "logoURL": "assets/logos/canyonRidgeServices.svg",
      "logoURLDark": "assets/logos/canyonRidgeServices.svg",
      "logoURLDarkCSS": {
         "height": "16px",
         "margin-top": "13px"
      },
      "poweredBy": true,
      "showFeatureRequestBoard": true,
      "validCustomers": [20736],
      "pageTitle": "Canyon Ridge Services"
   },
   "default": {
      "logoURL": "assets/img/logo-dark-background.svg",
      "logoURLDark": "assets/img/logo-light-background.svg",
      "logoURLDarkCSS": {
         "height": "32px"
      },
      "poweredBy": false,
      "showFeatureRequestBoard": true
   }
}
