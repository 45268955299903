import { inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import whiteLabelDomains from "src/app/shared/services/white-label/white-label-domains.json";
import { z } from "zod";

const whiteLabelSchema = z.object({
   validCustomers: z.array(z.number()).optional(),
   logoURL: z.string(),
   logoURLDark: z.string(),
   logoURLDarkCSS: z.record(z.string()),
   poweredBy: z.boolean(),
   showFeatureRequestBoard: z.boolean(),
   pageTitle: z.string().optional(),
   favicon16: z.string().optional(),
   favicon32: z.string().optional(),
   favicon96: z.string().optional(),
});

const whiteLabelDomainSchema = z.record(whiteLabelSchema);

@Injectable({ providedIn: "root" })
export class WhiteLabelService {
   private readonly data: z.infer<typeof whiteLabelSchema>;
   private readonly titleService = inject(Title);

   public constructor() {
      const hostname = window.location.hostname;
      const parsedDomains = whiteLabelDomainSchema.parse(whiteLabelDomains);
      this.data = parsedDomains[hostname] ?? parsedDomains.default;
   }

   public isValidCustomer(customerID: number): boolean {
      return (
         this.data.validCustomers === undefined ||
         this.data.validCustomers.includes(customerID)
      );
   }

   public logoCssDark(): Record<string, string> {
      return this.data.logoURLDarkCSS;
   }

   public shouldShowFeatureRequestBoard(): boolean {
      return this.data.showFeatureRequestBoard;
   }

   public favicon(size: 16 | 32 | 96): string | undefined {
      const mapper = {
         16: this.data.favicon16,
         32: this.data.favicon32,
         90: this.data.favicon96,
      } as const;
      return mapper[size];
   }

   public title(): string {
      return this.data.pageTitle ?? this.titleService.getTitle();
   }

   public shouldShowPoweredBy(): boolean {
      return this.data.poweredBy;
   }

   public logoUrl(): string {
      return this.data.logoURL;
   }

   public logoUrlDark(): string {
      return this.data.logoURLDark;
   }
}
